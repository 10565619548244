import {
	Col,
	Form,
	Input,
	message,
	Modal,
	notification,
	Popconfirm,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Upload,
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Container } from 'reactstrap'
import { Link, Switch, useHistory } from 'react-router-dom'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import {
	deleteHomeSlideBanner,
	getAllUsers,
	getHomeSlideBanners,
} from '../../helpers/helper'
import { UploadOutlined } from '@ant-design/icons'
import { uploadFileToBunny } from '../../helpers/api_bunny'
const { Column } = Table
const { Option } = Select
const dummy = {
	_id: 'fytiurytirytie',
	title: 'title',
	status: 'visible',
	index: 1,
	thumb: 'https://via.placeholder.com/150',
}
const HomeSlideBanner = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [banners, setBanners] = useState([])
	const [pageSize, setPageSize] = useState(10)
	const [pageIndex, setPageIndex] = useState(1)
	const [totalPageSize, setTotalPageSize] = useState(0)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [form] = Form.useForm()
	const [currentRecord, setCurrentRecord] = useState(null)
	//* start auth user
	const [users, setUsers] = useState([])
	const [userId, setUserId] = useState('')

	const authUser = JSON.parse(localStorage.getItem('authUser'))
	//* end auth user

	const history = useHistory();
  useEffect(() => {
    if (authUser?.role?._id === "6752a05569ada5485e88d1e3")
      history.replace("/dashboard", { replace: true });
  }, []);

	const url = 'https://gofiber.vn/'

	// async functions

	const getUsers = async () => {
		const result = await getAllUsers()
		if (result) {
			setUsers(result)
		}
	}

	const getBanners = async (pageSize, pageIndex) => {
		const result = await getHomeSlideBanners(pageSize, pageIndex)
		if (result) {
			setBanners(result.data)
			setTotalPageSize(result.total)
		}
	}

	const confirm = (post) => {
		if (post.link.url) {
			deleteHomeSlideBanner(post._id)
				.then((res) => {
					notification['success']({
						message: 'Notification',
						description: 'Delete banner successfully!',
					})
					getBanners(pageSize, pageIndex)
				})
				.catch((error) => {
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		}
	}

	//* useEffect
	useEffect(() => {
		getUsers()
	}, [])

	useEffect(() => {
		if (authUser?.role?.name !== 'Admin') {
			setUserId(authUser?.id)
			getBanners(pageSize, pageIndex, authUser?.id)
		} else {
			getBanners(pageSize, pageIndex, userId)
		}
	}, [pageSize, pageIndex, userId])

	return (
		<React.Fragment>
			<Spin spinning={isLoading}>
				<div className='page-content'>
					<Container fluid>
						<BreadCrumb
							title='Slide Banner Trang chủ'
							// pageTitle='Quản lý Slide banners trang chủ'
						/>

						<Row>
							<Col
								style={{ width: '200px' }}
								lg='6'
							>
								<Button>
									<Link
										to={{
											pathname: '/home-slide-banner/create/',
											// state: { id: val._id },
										}}
										style={{ color: '#fff' }}
									>
										Thêm mới banner
									</Link>
								</Button>
							</Col>
						</Row>
						<Row>
							<Col lg={24}>
								<Table
									rowKey='_id'
									pagination={false}
									dataSource={banners}
								>
									<Column
										title='#'
										render={(val, rec, index) => {
											return index + 1
										}}
									/>

									<Column
										title='Tiêu đề'
										dataIndex='title'
										key='title'
									/>
									<Column
										title='Đường dẫn'
										dataIndex='link'
										key='link'
										render={(link) => {
											return (
												<a
													href={link.url}
													target={link.target}
												>
													{link.url}
												</a>
											)
										}}
									/>
									<Column
										title='Hình ảnh'
										dataIndex='thumbnail'
										key='thumbnail'
										width={'180px'}
										render={(thumbnail) => {
											return (
												<img
													src={thumbnail.url}
													alt={thumbnail.alt}
													style={{ width: '100%' }}
												/>
											)
										}}
									/>
									<Column
										title='Trạng thái'
										dataIndex='status'
										key='status'
										render={(item) => {
											let rs = ''
											if (item === 'visible') {
												rs = 'Đang hiển thị'
											}
											if (item === 'hidden') {
												rs = 'Ẩn'
											}
											return <>{rs}</>
										}}
									/>
									<Column
										title='Thứ tự'
										dataIndex='order'
										key='order'
										render={(item) => <p>{item}</p>}
									/>

									<Column
										title='Hoạt động'
										key='action'
										render={(val, record) => (
											<Space size='middle'>
												<Link
													to={{
														pathname:
															'/home-slide-banner/edit/' + val._id,
														// state: { id: val._id },
													}}
												>
													<i className='ri-pencil-line action-icon'></i>
												</Link>
												<Popconfirm
													title='Are you sure to delete this post?'
													onConfirm={() => confirm(val)}
													okText='Yes'
													cancelText='No'
												>
													<i className='ri-delete-bin-line action-icon'></i>
												</Popconfirm>
											</Space>
										)}
									/>
								</Table>
							</Col>
						</Row>
					</Container>
				</div>
			</Spin>
		</React.Fragment>
	)
}

export default HomeSlideBanner
