import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BiCommentEdit } from 'react-icons/bi'
import { AiOutlineLink } from 'react-icons/ai'
import { RiLinksFill } from 'react-icons/ri'
import { TfiWorld } from 'react-icons/tfi'
const Navdata = () => {
	const history = useHistory()
	//state data
	const [isDashboard, setIsDashboard] = useState(false)
	const [isUsers, setIsUsers] = useState(false)
	const [isFAQs, setIsFAQs] = useState(false)
	const [isSchemas, setIsSchemas] = useState(false)
	const [isTaxonomy, setIsTaxonomy] = useState(false)
	const [isRoles, setIsRoles] = useState(false)
	const [isCates, setisCates] = useState(false)
	const [isPosts, setIsPosts] = useState(false)
	const [isLinks, setIsLinks] = useState(false)
	const [isMedia, setIsMedia] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [isBanners, setIsBanners] = useState(false)
	const [isStatistics, setIsStatistics] = useState(false)
	const [isDomains, setIsDomains] = useState(false)
	const [iscurrentState, setIscurrentState] = useState('Dashboard')
	const [isAutoLink, setIsAutoLink] = useState(false)
	const [isFP, setIsFP] = useState(false)
	const [isBlacklist, setIsBlackList] = useState(false)
	const [isWhitelist, setIsWhitelist] = useState(false)
	const [isService, setIsService] = useState(false)
	const [isSeo, setIsSeo] = useState(false)
	const [isRecruitment, setIsRecruitment] = useState(false)
	const [isHomeSlideBanner, setIsHomeSlideBanner] = useState(false)
	const [isContact, setIsContact] = useState(false)
	function updateIconSidebar(e) {
		if (e && e.target && e.target.getAttribute('subitems')) {
			const ul = document.getElementById('two-column-menu')
			const iconItems = ul.querySelectorAll('.nav-icon.active')
			let activeIconItems = [...iconItems]
			activeIconItems.forEach((item) => {
				item.classList.remove('active')
				var id = item.getAttribute('subitems')
				if (document.getElementById(id))
					document.getElementById(id).classList.remove('show')
			})
		}
	}
	//
	useEffect(() => {
		document.body.classList.remove('twocolumn-panel')
		if (iscurrentState !== 'Dashboard') {
			setIsDashboard(false)
		}
		if (iscurrentState !== 'Users') {
			setIsUsers(false)
		}
		if (iscurrentState !== 'FAQs') {
			setIsFAQs(false)
		}
		if (iscurrentState !== 'Schemas') {
			setIsSchemas(false)
		}
		if (iscurrentState !== 'Taxonomy') {
			setIsTaxonomy(false)
		}
		if (iscurrentState !== 'Categorys') {
			setisCates(false)
		}
		if (iscurrentState !== 'Posts') {
			setIsPosts(false)
		}
		if (iscurrentState !== 'Links') {
			setIsLinks(false)
		}
		if (iscurrentState !== 'Media') {
			setIsMedia(false)
		}
		if (iscurrentState !== 'Banners') {
			setIsBanners(false)
		}
		if (iscurrentState !== 'Statistics') {
			setIsStatistics(false)
		}
		if (iscurrentState !== 'Redirects') {
			setIsRedirect(false)
		}
		if (iscurrentState !== 'Autolinks') {
			setIsAutoLink(false)
		}
		if (iscurrentState !== 'Domains') {
			setIsDomains(false)
		}
		if (iscurrentState !== 'FP') {
			setIsFP(false)
		}
		if (iscurrentState !== 'Blacklist') {
			setIsBlackList(false)
		}
		if (iscurrentState !== 'Whitelist') {
			setIsWhitelist(false)
		}
		if (iscurrentState !== 'Service') {
			setIsService(false)
		}
		if (iscurrentState !== 'Seo') {
			setIsSeo(false)
		}
		if (iscurrentState !== 'Recruitment') {
			setIsRecruitment(false)
		}
		if (iscurrentState !== 'HomeSlideBanner') {
			setIsHomeSlideBanner(false)
		}
		if (iscurrentState !== 'Contact') {
			setIsContact(false)
		}
	}, [
		history,
		iscurrentState,
		isDashboard,
		isUsers,
		isFAQs,
		isSchemas,
		isTaxonomy,
		isCates,
		isLinks,
		isMedia,
		isBanners,
		isStatistics,
		isRedirect,
		isAutoLink,
		isDomains,
		isBlacklist,
		isWhitelist,
		isSeo,
		isRecruitment,
		isHomeSlideBanner,
		isContact,
	])

	const menuItems = [
		{
			label: 'Menu',
			isHeader: true,
		},
		{
			id: 'dashboard',
			label: 'BẢNG ĐIỀU KHIỂN',
			icon: 'ri-dashboard-2-line',
			link: '/#',
			stateVariables: isDashboard,
			click: function (e) {
				e.preventDefault()
				setIsDashboard(!isDashboard)
				setIscurrentState('Dashboard')
				updateIconSidebar(e)
			},
			subItems: [
				// {
				//   id: "analytics",
				//   label: "Analytics",
				//   link: "/dashboard-analytics",
				//   parentId: "dashboard",
				// },
			],
		},
		{
			id: 'users',
			label: 'QUẢN LÝ THÀNH VIÊN',
			icon: 'ri-user-2-line',
			link: '/#',
			stateVariables: isUsers,
			click: function (e) {
				e.preventDefault()
				setIsUsers(!isUsers)
				setIscurrentState('Users')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'user-management',
					label: 'Thành Viên',
					link: '/users',
					parentId: 'users',
				},
				// {
				//   id: "user-permission",
				//   label: "Phân Quyền",
				//   link: "/permission",
				//   parentId: "users",
				// },
			],
		},
		{
			id: 'fp',
			label: 'QUẢN LÝ FP',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isFP,
			click: function (e) {
				e.preventDefault()
				setIsFP(!isFP)
				setIscurrentState('FP')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'fp-management',
					label: 'FP',
					link: '/fp',
					parentId: 'fp',
				},
			],
		},
		{
			id: 'schema-management',
			label: 'QUẢN LÝ SCHEMA',
			icon: 'ri-bookmark-line',
			link: '/#',
			stateVariables: isSchemas,
			click: function (e) {
				e.preventDefault()
				setIsSchemas(!isSchemas)
				setIscurrentState('Schemas')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'schemas',
					label: 'Schemas',
					link: '/schemas',
					parentId: 'schema-management',
				},
			],
		},
		{
			id: 'roles',
			label: 'QUẢN LÍ PHÂN QUYỀN',
			icon: 'ri-user-2-line',
			link: '/#',
			stateVariables: isRoles,
			click: function (e) {
				e.preventDefault()
				setIsRoles(!isRoles)
				setIscurrentState('Roles')
			},
			subItems: [
				{
					id: 'roles-management',
					label: 'QUYỀN HẠN',
					link: '/roles',
					parentId: 'roles',
				},
			],
		},
		{
			id: 'taxonomy-management',
			label: 'CHUYÊN MỤC',
			icon: 'ri-price-tag-3-line',
			link: '/#',
			stateVariables: isTaxonomy,
			click: function (e) {
				e.preventDefault()
				setIsTaxonomy(!isTaxonomy)
				setIscurrentState('Taxonomy')
			},
			subItems: [
				{
					id: 'taxonomy',
					label: 'Danh mục',
					link: '/categories',
					parentId: 'taxonomy-management',
				},
				{
					id: 'taxonomy',
					label: 'Thẻ',
					link: '/tags',
					parentId: 'taxonomy-management',
				},
			],
		},
		{
			id: 'cate-management',
			label: 'QUẢN LÝ DANH MỤC',
			icon: ' ri-pages-line',
			link: '/#',
			stateVariables: isCates,
			click: function (e) {
				e.preventDefault()
				setisCates(!isCates)
				setIscurrentState('Categorys')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'cate',
					label: 'Category',
					link: '/cate-management',
					parentId: 'cate-management',
				},
			],
		},
		{
			id: 'post-management',
			label: 'QUẢN LÝ BÀI VIẾT',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isPosts,
			click: function (e) {
				e.preventDefault()
				setIsPosts(!isPosts)
				setIscurrentState('Posts')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'posts',
					label: 'Bài Viết',
					link: '/posts',
					parentId: 'post-management',
				},
				{
					id: 'recruits',
					label: 'Tuyển dụng',
					link: '/recruits',
					parentId: 'post-management',
				},
			],
		},
		{
			id: 'redirect-management',
			label: 'QUẢN LÝ REDIRECT',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isRedirect,
			click: function (e) {
				e.preventDefault()
				setIsRedirect(!isRedirect)
				setIscurrentState('Redirects')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'redirect',
					label: 'Quản lí Redirect',
					link: '/redirect',
					parentId: 'redirect-management',
				},
			],
		},
		{
			id: 'autolink-management',
			label: 'QUẢN LÝ AUTOLINK',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isAutoLink,
			click: function (e) {
				e.preventDefault()
				setIsAutoLink(!isAutoLink)
				setIscurrentState('Autolinks')
			},
			subItems: [
				{
					id: 'autolink',
					label: 'Quản lí Autolink',
					link: '/autolink',
					parentId: 'autolink-management',
				},
			],
		},
		{
			id: 'domain-management',
			label: 'QUẢN LÝ DOMAINS',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isDomains,
			click: function (e) {
				e.preventDefault()
				setIsDomains(!isDomains)
				setIscurrentState('Domains')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'domains',
					label: 'Quản lý Domains',
					link: '/domains',
					parentId: 'domains-management',
				},
			],
		},
		{
			id: 'whitelist-management',
			label: 'QUẢN LÝ WHITELIST',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isWhitelist,
			click: function (e) {
				e.preventDefault()
				setIsWhitelist(!isWhitelist)
				setIscurrentState('Whitelist')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'whitelist',
					label: 'Quản lý whitelist',
					link: '/whitelist',
					parentId: 'whitelist-management',
				},
			],
		},
		{
			id: 'blacklist-management',
			label: 'QUẢN LÝ BLACKLIST',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isBlacklist,
			click: function (e) {
				e.preventDefault()
				setIsBlackList(!isBlacklist)
				setIscurrentState('Blacklist')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'blacklist',
					label: 'Quản lý blacklist',
					link: '/blacklist',
					parentId: 'blacklist-management',
				},
			],
		},
		{
			id: 'service-management',
			label: 'QUẢN LÝ SERVICE',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isService,
			click: function (e) {
				e.preventDefault()
				setIsService(!isService)
				setIscurrentState('Service')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'vps-tab',
					label: 'Quản lý VPS-TAB',
					link: '/vps-tab',
					parentId: 'vps-management',
				},
				{
					id: 'vps',
					label: 'Quản lý VPS',
					link: '/vps',
					parentId: 'vps-management',
				},
				{
					id: 'hosting',
					label: 'Quản lý HOSTING',
					link: '/hosting',
					parentId: 'hosting-management',
				},
			],
		},
		{
			id: 'seo-management',
			label: 'QUẢN LÝ SEO',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isSeo,
			click: function (e) {
				e.preventDefault()
				setIsSeo(!isSeo)
				setIscurrentState('Seo')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'seo',
					label: 'Quản lý Seo',
					link: '/seo',
					parentId: 'seo-management',
				},
			],
		},
		{
			id: 'recruitment-management',
			label: 'QUẢN LÝ HS ỨNG TUYỂN',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isRecruitment,
			click: function (e) {
				e.preventDefault()
				setIsRecruitment(!isRecruitment)
				setIscurrentState('Recruitment')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'recruitment',
					label: 'Quản lý HS Ứng tuyển',
					link: '/recruitment',
					parentId: 'recruitment-management',
				},
			],
		},
		{
			id: 'banner-management',
			label: 'QUẢN LÝ BANNER',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isHomeSlideBanner,
			click: function (e) {
				e.preventDefault()
				setIsHomeSlideBanner(!isHomeSlideBanner)
				setIscurrentState('HomeSlideBanner')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'home-slide-banner',
					label: 'Quản lý slide banner trang chủ',
					link: '/home-slide-banner',
					parentId: 'banner-management',
				},
				{
					id: 'page-banner',
					label: 'Quản lý banner quảng cáo',
					link: '/page-banner',
					parentId: 'banner-management',
				},
			],
		},
		{
			id: 'contact-management',
			label: 'QUẢN LÝ LIÊN HỆ',
			icon: 'ri-archive-line',
			link: '/#',
			stateVariables: isContact,
			click: function (e) {
				e.preventDefault()
				setIsContact(!isContact)
				setIscurrentState('Contact')
				updateIconSidebar(e)
			},
			subItems: [
				{
					id: 'contact-client',
					label: 'Quản lý liên hệ',
					link: '/contact-client',
					parentId: 'contact-management',
				},
			],
		},

		// {
		//   id: "faqs-management",
		//   label: "QUẢN LÝ FAQs",
		//   icon: "ri-questionnaire-line",
		//   link: "/#",
		//   stateVariables: isFAQs,
		//   click: function (e) {
		//     e.preventDefault();
		//     setIsFAQs(!isFAQs);
		//     setIscurrentState("FAQs");
		//     updateIconSidebar(e);
		//   },
		//   subItems: [
		//     {
		//       id: "faqs",
		//       label: "FAQs",
		//       link: "/faqs",
		//       parentId: "faqs-management",
		//     },
		//   ],
		// },
		// {
		//   id: "links-management",
		//   label: "QUẢN LÝ LINK",
		//   icon: "ri-links-line",
		//   link: "/#",
		//   stateVariables: isLinks,
		//   click: function (e) {
		//     e.preventDefault();
		//     setIsLinks(!isLinks);
		//     setIscurrentState("Links");
		//     updateIconSidebar(e);
		//   },
		//   subItems: [
		//     {
		//       id: "links",
		//       label: "Links",
		//       link: "/links",
		//       parentId: "links-management",
		//     },
		//   ],
		// },
		// {
		//   id: "media-management",
		//   label: "QUẢN LÝ MEDIA",
		//   icon: "ri-image-line",
		//   link: "/#",
		//   stateVariables: isMedia,
		//   click: function (e) {
		//     e.preventDefault();
		//     setIsMedia(!isMedia);
		//     setIscurrentState("Media");
		//     updateIconSidebar(e);
		//   },
		//   subItems: [
		//     {
		//       id: "media",
		//       label: "Media",
		//       link: "/media-management",
		//       parentId: "media-management",
		//     },
		//   ],
		// },
		// {
		//   id: "banners-management",
		//   label: "QUẢN LÝ BANNER",
		//   icon: "ri-image-line",
		//   link: "/#",
		//   stateVariables: isBanners,
		//   click: function (e) {
		//     e.preventDefault();
		//     setIsBanners(!isBanners);
		//     setIscurrentState("Banners");
		//     updateIconSidebar(e);
		//   },
		//   subItems: [
		//     {
		//       id: "banners",
		//       label: "Banners",
		//       link: "/banners-management",
		//       parentId: "banners-management",
		//     },
		//   ],
		// },
		// {
		//   id: "statistics",
		//   label: "THỐNG KÊ",
		//   icon: "ri-filter-3-line",
		//   link: "/#",
		//   stateVariables: isStatistics,
		//   click: function (e) {
		//     e.preventDefault();
		//     setIsStatistics(!isStatistics);
		//     setIscurrentState("Statistics");
		//     updateIconSidebar(e);
		//   },
		//   subItems: [
		//     {
		//       id: "post-statistics",
		//       label: "Bài viết",
		//       link: "/post-statistics",
		//       parentId: "statistics",
		//     },
		//     {
		//       id: "user-statistics",
		//       label: "Nhân viên",
		//       link: "/user-statistics",
		//       parentId: "statistics",
		//     },
		//   ],
		// },
	]
	return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
