import {
	Drawer,
	Form,
	message,
	Pagination,
	Popconfirm,
	Select,
	Space,
	Table,
	Tooltip,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
	Button,
	Col,
	Container,
	Input,
	InputGroup,
	InputGroupText,
	Label,
	Row,
} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import toSlug from '../../common/function'
import {
	addHostingV2,
	deleteHostingV2,
	getAllHostingV2,
	getPagingHosting,
	updateHostingV2,
} from '../../helpers/helper'
const { Column } = Table
const { Option } = Select

function HostingList() {
	const [hosting, setHosting] = useState([])
	const [drawerTitle, setDrawerTitle] = useState('')
	const [form] = Form.useForm()
	const [visibleForm, setVisibleForm] = useState(false)
	const [limit, setLimit] = useState(10)
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState('')
	const [total, setTotal] = useState(1)
	const [status, setStatus] = useState(0)
	const [isBest, setIsBest] = useState(false)
	const [rateDiscount12m, setRateDiscount12m] = useState(0)
	const [rateDiscount6m, setRateDiscount6m] = useState(0)
	const [rateDiscount3m, setRateDiscount3m] = useState(0)
	const [rateDiscount1m, setRateDiscount1m] = useState(0)

	const getHosting = () => {
		getAllHostingV2(limit, page, filter).then((res) => {
			setHosting(res.data)
			setLimit(res.limit)
			setTotal(res.totalItem)
		})
	}

	useEffect(() => {
		getHosting()
	}, [limit, page])

	const onClose = () => {
		setVisibleForm(false)
	}

	const handleChangeIsBest = () => {
		setIsBest(!isBest)
	}

	const onFinish = async (data) => {
		let dataReq = {
			name: data.name,
			desc: data.desc,
			price: Number(data?.price) || 0,
			isActive: data.isActive,
		}

		dataReq = {
			...dataReq,
			rate_discount_1m: Number(rateDiscount1m) || 0,
			rate_discount_3m: Number(rateDiscount3m) || 0,
			rate_discount_6m: Number(rateDiscount6m) || 0,
			rate_discount_12m: Number(rateDiscount12m) || 0,
		}

		if (!data._id) {
			//Save
			dataReq.id = Math.floor(Math.random() * 10000) + 10000
			const dataRes = await addHostingV2(dataReq)
			dataRes.status === 'success'
				? message.success(`Save Success! `)
				: message.error(`Save Failed! ${dataRes.message}`)
			if (dataRes.success === false) {
				onClose()
			}
		} else {
			//Update
			const dataRes = await updateHostingV2(data._id, dataReq)
			dataRes.status === 'success'
				? message.success(`Update Success!`)
				: message.error(`Update Failed! ${dataRes.message}`)
			if (dataRes.success === true) {
				onClose()
			}
		}

		form.resetFields()
		getHosting(limit, page)
	}
	const handleRefreshCreate = async () => {
		form.resetFields()
	}
	const onEdit = (key) => {
		const dataEdit = hosting.filter((item) => item._id === key)

		form.setFieldsValue({
			_id: dataEdit[0]._id,
			name: dataEdit[0].name,
			desc: dataEdit[0].desc,
			price: Number(dataEdit[0]?.price) || 0,
			isActive: dataEdit[0].isActive,
		})

		setRateDiscount1m(dataEdit[0].rate_discount_1m)
		setRateDiscount3m(dataEdit[0].rate_discount_3m)
		setRateDiscount6m(dataEdit[0].rate_discount_6m)
		setRateDiscount12m(dataEdit[0].rate_discount_12m)

		showDrawer()
		setDrawerTitle('Chỉnh Sửa Hosting')
	}
	const showDrawer = () => {
		setVisibleForm(true)
	}

	const onSearch = () => {
		getPagingHosting()
	}

	const onPressDiscount = (fieldName, e) => {
		if (e.key === 'Enter' && e.target.value) {
			switch (fieldName) {
				case 'rate_discount_1m':
					setRateDiscount1m(e.target.value)
					break
				case 'rate_discount_3m':
					setRateDiscount3m(e.target.value)
					break
				case 'rate_discount_6m':
					setRateDiscount6m(e.target.value)
					break
				case 'rate_discount_12m':
					setRateDiscount12m(e.target.value)
					break
				default:
					break
			}
		}
	}

	const onDiscountChange = (fieldName, e) => {
		switch (fieldName) {
			case 'rate_discount_1m':
				setRateDiscount1m(e.target.value)
				break
			case 'rate_discount_3m':
				setRateDiscount3m(e.target.value)
				break
			case 'rate_discount_6m':
				setRateDiscount6m(e.target.value)
				break
			case 'rate_discount_12m':
				setRateDiscount12m(e.target.value)
				break
			default:
				break
		}
	}

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb
						title='Hosting'
						pageTitle='Quản lý Service'
					/>
					<Row className='mb-3'>
						<Drawer
							title={drawerTitle}
							placement={'right'}
							size='large'
							onClose={onClose}
							open={visibleForm}
							bodyStyle={{
								paddingBottom: 80,
							}}
							style={{ marginTop: '70px' }}
						>
							<Form
								form={form}
								layout='vertical'
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault()
								}}
								onFinish={onFinish}
								autoComplete='off'
							>
								<Row>
									<Col
										sm={4}
										hidden={true}
									>
										<Form.Item
											name='_id'
											label='Id'
										>
											<Input name='_id' />
										</Form.Item>
									</Col>
									<Col>
										<Form.Item
											name='name'
											label='Tên hosting'
											rules={[
												{
													required: true,
													message: 'Vui lòng nhập tên hosting!',
												},
												{
													type: 'name',
												},
												{
													type: 'string',
													min: 1,
												},
											]}
										>
											<Input
												placeholder='Nhập tên hosting'
												name='name'
												allowClear={true}
												onChange={(e) =>
													form.setFieldsValue({
														slug: toSlug(e.target.value),
													})
												}
												// onChange={(e) => handleChangeTitle(e.target.value)}
											/>
										</Form.Item>
									</Col>
									<Col xs={12}>
										<Form.Item
											name='desc'
											label='Mô tả'
										>
											<Input
												placeholder='Nhập mô tả hosting'
												name='name'
												allowClear={true}
											/>
										</Form.Item>
									</Col>
									<Col xs={12}>
										<Form.Item
											name='price'
											label='Giá'
											rules={[
												{
													required: true,
													message: 'Vui lòng nhập giá!',
												},
												{
													type: 'price',
												},
											]}
										>
											<Input
												placeholder='Nhập giá'
												name='price'
												allowClear={true}
											/>
										</Form.Item>
									</Col>
									<Col
										xs={12}
										className='mb-2'
									>
										<Label>Phần trăm giảm giá gói 1 tháng</Label>
										<Input
											id='rate_discount_1m'
											name='rate_discount_1m'
											className='mb-1'
											placeholder='Nhập xong nhấp enter'
											type='number'
											value={rateDiscount1m}
											onKeyPress={(e) =>
												onPressDiscount('rate_discount_1m', e)
											}
											onChange={(e) => {
												onDiscountChange('rate_discount_1m', e)
											}}
										/>
									</Col>
									<Col
										xs={12}
										className='mb-2'
									>
										<Label>Phần trăm giảm giá gói 3 tháng</Label>
										<Input
											id='rate_discount_3m'
											name='rate_discount_3m'
											className='mb-1'
											placeholder='Nhập xong nhấp enter'
											type='number'
											value={rateDiscount3m}
											onKeyPress={(e) =>
												onPressDiscount('rate_discount_3m', e)
											}
											onChange={(e) => {
												onDiscountChange('rate_discount_3m', e)
											}}
										/>
									</Col>
									<Col
										xs={12}
										className='mb-2'
									>
										<Label>Phần trăm giảm giá gói 6 tháng</Label>
										<Input
											id='rate_discount_6m'
											name='rate_discount_6m'
											className='mb-1'
											placeholder='Nhập xong nhấp enter'
											type='number'
											value={rateDiscount6m}
											onKeyPress={(e) =>
												onPressDiscount('rate_discount_6m', e)
											}
											onChange={(e) => {
												onDiscountChange('rate_discount_6m', e)
											}}
										/>
									</Col>
									<Col
										xs={12}
										className='mb-2'
									>
										<Label>Phần trăm giảm giá gói 12 tháng</Label>
										<Input
											id='rate_discount_12m'
											name='rate_discount_12m'
											className='mb-1'
											placeholder='Nhập xong nhấp enter'
											type='number'
											value={rateDiscount12m}
											onKeyPress={(e) =>
												onPressDiscount('rate_discount_12m', e)
											}
											onChange={(e) => {
												onDiscountChange('rate_discount_12m', e)
											}}
										/>
									</Col>

									<Col xs={12}>
										<Form.Item
											name='isActive'
											label='Trạng thái'
										>
											<Select
												placeholder='Chọn trạng thái'
												value={status}
												defaultValue={status}
												// key={formAdd.status}
												style={{ width: '200px' }}
												onChange={(e) => setStatus(e)}
											>
												<Option
													label='Dừng hoạt động'
													key={0}
													value={false}
												>
													Dừng hoạt động
												</Option>
												<Option
													label='Đang hoạt động'
													key={1}
													value={true}
												>
													Đang hoạt động
												</Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Form.Item>
									<Space>
										<Button
											type='primary'
											htmlType='submit'
										>
											Save
										</Button>

										<Button
											type='primary'
											htmlType='button'
											onClick={() => handleRefreshCreate()}
										>
											Refresh
										</Button>
									</Space>
								</Form.Item>
							</Form>
						</Drawer>
						<Col lg='5'>
							<div>
								<InputGroup>
									<Input
										// value={searchText}
										onChange={(e) => {
											setFilter(e.target.value)
										}}
										placeholder='Tìm kiếm...'
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												setFilter(e.target.value)
											}
										}}
									/>
									<InputGroupText
										style={{ cursor: 'pointer' }}
										onClick={onSearch}
									>
										<i className='ri-search-line'></i>
									</InputGroupText>
								</InputGroup>
							</div>
						</Col>

						<Col lg='7'>
							<div className='text-right'>
								<Button
									onClick={() => {
										setDrawerTitle('Thêm Hosting mới')
										showDrawer()
										form.resetFields()
									}}
								>
									Thêm mới
								</Button>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={12}>
							<Table
								rowKey='_id'
								dataSource={hosting}
								pagination={false}
								// onChange={(e) => handleOnChangeTable(e)}
							>
								<Column
									title='#'
									render={(val, rec, index) => {
										return index + 1
									}}
								/>
								<Column
									title='Tên'
									dataIndex='name'
									key='name'
								/>
								<Column
									title='Mô tả'
									dataIndex='desc'
									key='desc'
								/>

								<Column
									title='Giá'
									dataIndex='price'
									key='price'
									render={(item) => (
										<>
											{Intl.NumberFormat('en-US').format(item || 0)}{' '}
											vnđ
										</>
									)}
								/>

								<Column
									title='Giảm giá gói 1 tháng'
									dataIndex='rate_discount_1m'
									key='rate_discount_1m'
									render={(item) => <>{item}%</>}
								/>
								<Column
									title='Giảm giá gói 3 tháng'
									dataIndex='rate_discount_3m'
									key='rate_discount_3m'
									render={(item) => <>{item}%</>}
								/>
								<Column
									title='Giảm giá gói 6 tháng'
									dataIndex='rate_discount_6m'
									key='rate_discount_6m'
									render={(item) => <>{item}%</>}
								/>
								<Column
									title='Giảm giá gói 12 tháng'
									dataIndex='rate_discount_12m'
									key='rate_discount_12m'
									render={(item) => <>{item}%</>}
								/>
								{/* show dev only */}
								{/* <Column
									title='Dung lượng ổ đĩa'
									dataIndex='disk_capacity'
									key='disk_capacity'
								/>
								<Column
									title='Dung lượng RAM'
									dataIndex='ram_capacity'
									key='ram_capacity'
								/>
								<Column
									title='Băng thông'
									dataIndex='bandwidth'
									key='bandwidth'
								/>
								<Column
									title='Số lượng Domain'
									dataIndex='domain_quantity'
									key='domain_quantity'
								/>
								<Column
									title='Công cụ xây dựng trang web'
									dataIndex='webkit_tool'
									key='webkit_tool'
									render={(item) => {
										return item ? 'Có' : 'Không'
									}}
								/>
								<Column
									title='Phương pháp hỗ trợ'
									dataIndex='support_method'
									key='support_method'
									render={(item) => {
										return item ? 'Có' : 'Không'
									}}
								/>
								<Column
									title='Hỗ trợ vip'
									dataIndex='vip_support'
									key='vip_support'
									render={(item) => {
										return item ? 'Có' : 'Không'
									}}
								/>
								<Column
									title='Số tài khoản trên mỗi máy chủ'
									dataIndex='account_quantity'
									key='account_quantity'
								/>
								<Column
									title='Số Inodes tối đa'
									dataIndex='max_Inodes'
									key='max_Inodes'
								/>
								<Column
									title='CPU'
									dataIndex='cpu'
									key='cpu'
								/>
								<Column
									title='Số kêt nối MySQL'
									dataIndex='sql_connect_quantity'
									key='sql_connect_quantity'
								/>
								<Column
									title='SLA'
									dataIndex='sla'
									key='sla'
								/>
								<Column
									title='Khắc phục troubleshooting'
									dataIndex='fix_troubleshooting'
									key='fix_troubleshooting'
									render={(item) => {
										return item ? 'Có' : 'Không'
									}}
								/>
								<Column
									title='Sửa chữa sau khi bị hack/xâm nhập'
									dataIndex='hack_fixable'
									key='hack_fixable'
									render={(item) => {
										return item ? 'Có' : 'Không'
									}}
								/>
								<Column
									title='Lọc thư rác'
									dataIndex='filler_spam'
									key='filler_spam'
									render={(item) => {
										return item ? 'Có' : 'Không'
									}}
								/>
								<Column
									title='Chống ddos'
									dataIndex='ddos'
									key='ddos'
									render={(item) => {
										return item ? 'Có' : 'Không'
									}}
								/> */}

								<Column
									title='Trạng thái'
									dataIndex='isActive'
									key='isActive'
									render={(item) => {
										let rs = ''

										if (item === true) {
											rs = 'Đang hoạt động'
										}
										if (item === false) {
											rs = 'Dừng hoạt động'
										}
										return <>{rs}</>
									}}
								/>
								<Column
									title='Hoạt động'
									key='action'
									render={(val, record) => (
										<Space size='middle'>
											<Tooltip title='Edit'>
												<i
													className='ri-pencil-line action-icon'
													style={{ color: 'blue' }}
													onClick={() => onEdit(val._id)}
												></i>
											</Tooltip>

											<Popconfirm
												title='Are you sure to delete this hosting?'
												onConfirm={() => {
													deleteHostingV2(val._id).then(() => {
														getHosting(limit, page)
													})
												}}
												okText='Yes'
												cancelText='No'
											>
												<i className='ri-delete-bin-line action-icon'></i>
											</Popconfirm>
										</Space>
									)}
								/>
							</Table>
							<Row>
								<Col className='d-flex justify-content-end'>
									<Pagination
										// showTotal={showTotal}
										style={{ marginTop: '20px' }}
										current={page}
										defaultCurrent={page}
										total={total}
										limit={limit}
										showSizeChanger
										onChange={(page, limit) => {
											setPage(page)
											setLimit(limit)
										}}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

export default HostingList
