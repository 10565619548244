import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap'

//import images

const ProfileDropdown = () => {
	const { user } = useSelector((state) => ({
		user: state.Profile.user,
	}))

	const [userName, setUserName] = useState('Admin')
	const [avatar, setAvatar] = useState('')

	useEffect(() => {
		if (localStorage.getItem('authUser')) {
			const obj = JSON.parse(localStorage.getItem('authUser'))
			setUserName(user.firstName || obj.firstName || 'Admin')
			setAvatar(user.avatar || obj.avatar || '')
		}
	}, [userName, user])

	//Dropdown Toggle
	const [isProfileDropdown, setIsProfileDropdown] = useState(false)
	const toggleProfileDropdown = () => {
		setIsProfileDropdown(!isProfileDropdown)
	}
	const authUser = JSON.parse(localStorage.getItem('authUser'))

	return (
		<React.Fragment>
			<Dropdown
				isOpen={isProfileDropdown}
				toggle={toggleProfileDropdown}
				className='ms-sm-3 header-item topbar-user'
			>
				<DropdownToggle
					tag='button'
					type='button'
					className='btn'
				>
					<span className='d-flex align-items-center'>
						<img
							className='rounded-circle header-profile-user'
							src={avatar ? avatar : '/images/default-avatar.jpeg'}
							alt='Header Avatar'
						/>
						<span className='text-start ms-xl-2'>
							<span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
								{userName}
							</span>
							<span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
								{authUser?.role?.name || 'Admin'}
							</span>
						</span>
					</span>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-end'>
					<h6 className='dropdown-header'>Welcome {userName}!</h6>
					<DropdownItem href='/profile'>
						<i className='mdi mdi-account-circle text-muted fs-16 align-middle me-1'></i>
						<span className='align-middle'>Profile</span>
					</DropdownItem>
					<div className='dropdown-divider'></div>
					<DropdownItem href='/logout'>
						<i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
						<span
							className='align-middle'
							data-key='t-logout'
						>
							Logout
						</span>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	)
}

export default ProfileDropdown
